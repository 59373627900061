import { Button } from "@mui/material";
import { get_date_format } from "./HelperFunctions"

export default function SubmitButton() {
    function setTimeStamp() {
        function updateTime() {
            // get the current date
            let currentDate = new Date();
            // convert it to "short time" format
            let t = get_date_format(currentDate);
            // get the DOM element
            let root = document.querySelector("#timestamp");
            // print the formatted date
            if (!(root === null)) {
                root.textContent = t;
            }
        }

        function init() {
            window.setInterval(updateTime);
        }
        init();
    }
    return (
        <Button
           // value={{ className: "addButtonIcon" }}
            variant="contained"
            type="submit"
            onClick={setTimeStamp}
            className="addButtonIcon"
        >
            Print Preview
        </Button>
    );
}
