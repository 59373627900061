import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CashCheckGIKDataEntry from "../components/CashCheckGIKDataEntry";
import { parseNum } from "../components/HelperFunctions";
import InstructionsGIK from "../components/InstructionsGIK";

type GiftInKindParams = {
    formValues: any;
    setFormValues: Function;
};

export default function GiftInKind({
    formValues,
    setFormValues,
}: GiftInKindParams) {
    const methods = useForm({
        defaultValues: formValues,
        mode: "onChange",
    });

    const navigate = useNavigate();
    function removeEmptyFields(data: any) {}

    const onSubmit = (data: any) => {
        console.log("Form Submitted with Data:", data);
        removeEmptyFields(data);
        setFormValues(data);

        if (parseNum(data.gikTotal) === parseNum(data.overallTotal)) {
            navigate("/giftinkindprint");
        }
    };
    document.title = "U of I Foundation | Gift in Kind Transmittal Form";
    return (
        <>
            <FormProvider {...methods}>
                <InstructionsGIK formType={"GiftInKind"} />
                <CashCheckGIKDataEntry
                    showAnonymousDonorCheckBox={false}
                    formType={"GiftInKind"}
                    onSubmit={onSubmit}
                />
                <button></button>
            </FormProvider>
        </>
    );
}
