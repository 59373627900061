import {
    Button,
    Container,
    // createTheme,
    // ThemeProvider,
    Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { createRef } from "react";
import PrintUIFOnlyContactInformation from "./PrintUIFOnlyContactInformation";
import PrintDonorInformation from "./PrintDonorInformation";
import PrintTribute from "./PrintTribute";
import PrintDesignations from "./PrintDesignations";
import PrintTotals from "./PrintTotals";
import PrintSpousalPartner from "./PrintSpousalPartner";
import PrintSecurities from "./PrintSecurities";

import PrintGiftInformation from "./PrintGiftInformation";
import PrintEightyTwoEightyThree from "./PrintEightyTwoEightyThree";
import PrintGIKs from "./PrintGIKs";
import PrintCheck from "./PrintCheck";
import PrintWire from "./PrintWire";
import PrintPlannedGift from "./PrintPlannedGift";
import PrintOtherInformation from "./PrintOtherInformation";
import PrintCampusAddressSwap from "../SplitCampusAddressSwap";

// import TrustIRADistribution from "../TrustIRADistribution";

type PrintParams = {
    formValues: any;
    returnUrl: string;
    formType: string;
    //printSecurities: boolean;
};

const TrustPrint = ({
    formValues,
    returnUrl,
    formType,
    //printSecurities,
}: PrintParams) => {
    const navigate = useNavigate();
    // const componentRef = useRef();
    const componentRef = createRef<HTMLDivElement>();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    // const theme = createTheme({
    //     typography: {
    //         formCat: {
    //             fontWeight: "bold",
    //         },
    //     },
    // });

    return (
        <>
            {/* <ThemeProvider theme={theme}> */}
                <div ref={componentRef}>
                    <Container>
                        <div id="timestamp" className="pb-2"></div>
                        <PrintCampusAddressSwap
                            campusLocation={formValues.campusLocation}
                            formType={formType}
                        />
                        <Container className="d-flex justify-content-center">
                            <div className="hideForPrint">
                                <Col className="trustPrintBtn">
                                    <Button
                                        variant="contained"
                                        className="buttonClass"
                                        onClick={() => {
                                            navigate(returnUrl);
                                        }}
                                    >
                                        Back
                                    </Button>
                                </Col>
                                <div className="col trustPrintBtn">
                                    <Button
                                        variant="contained"
                                        onClick={handlePrint}
                                        style={{ width: "max-content" }}
                                    >
                                        Print Document
                                    </Button>
                                </div>
                            </div>
                        </Container>

                        <div className="resultItems">
                            <Typography
                                variant="h5"
                                component={"h1"}
                                className="d-flex justify-content-center mb-2"
                            >
                                Trust Type: {formValues.trustType}
                            </Typography>
                            <PrintUIFOnlyContactInformation
                                formValues={formValues}
                            />
                        </div>
                        <PrintDonorInformation formValues={formValues} />
                        <PrintSpousalPartner formValues={formValues} />
                        <PrintTotals formValues={formValues} />
                        <PrintTribute formValues={formValues} />
                        <PrintGiftInformation formValues={formValues} />
                        <PrintSecurities formValues={formValues} />
                        <PrintWire formValues={formValues} />
                        <PrintCheck formValues={formValues} />
                        <PrintGIKs formValues={formValues} />
                        <PrintOtherInformation formValues={formValues} />
                        <PrintEightyTwoEightyThree formValues={formValues} />
                        <PrintDesignations formValues={formValues} />
                        <PrintPlannedGift formValues={formValues} />
                        <PrintTotals formValues={formValues} />
                        <Container className="d-flex justify-content-center">
                            <Row className="hideForPrint">
                                <>
                                    <Col className="trustPrintBtn">
                                        <Button
                                            variant="contained"
                                            className="buttonClass"
                                            onClick={() => {
                                                navigate(returnUrl);
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Col>
                                    <div className="col trustPrintBtn">
                                        <Button
                                            variant="contained"
                                            onClick={handlePrint}
                                            style={{ width: "max-content" }}
                                        >
                                            Print Document
                                        </Button>
                                    </div>
                                </>
                            </Row>
                        </Container>
                    </Container>
                </div>
            {/* </ThemeProvider> */}
        </>
    );
};

export default TrustPrint;
