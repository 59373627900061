import { ReactNode } from "react";
import { Typography } from "@mui/material";

type addrHeader = {
    headerLine?: ReactNode;
    formType?: string;
};

export default function UrbanaAddress({
    headerLine = "",
    formType,
}: addrHeader) {
    return (
        <Typography
            variant="subtitle2"
            component="div"
            className="mb-2"
            style={
                formType === "Gift in Kind" ||
                window.location.pathname === "/giftinkindprint"
                    ? {
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                      }
                    : {}
            }
        >
            {
                {
                    "/giftinKind": "Mail original forms to:",
                    "/giftinkindprint": "Mail original forms to:",
                    "/cash": "",
                    "/cashprint": "",
                }[window.location.pathname]
            }
            <br />
            {headerLine}
            University of Illinois{" "}
            {formType === "Gift in Kind" ? "Urbana-Champaign" : "Foundation"}
            <br />
            Attn: Cash Receipts
            <br />
            303 St. Mary's Road, MC-386
            <br />
            Champaign, IL 61820
        </Typography>
    );
}
