import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import Address from "./Address";
import { Button } from "@mui/base";

export default function GiftTribute() {
    const { register, getValues, setValue, control } = useFormContext();
    const [tributeType, setTributeType] = useState(
        localStorage.getItem("tributeType") || "In Memory Of"
    ); // initialize tributeType state

    // function to handle radio button change
    // function to handle radio button change
    const handleRadioChange = (
        event: React.SyntheticEvent,
        checked: boolean
    ) => {
        const target = event.target as HTMLInputElement;
        setTributeType(target.value);
        localStorage.setItem("tributeType", target.value); // store tributeType in localStorage
    };

    // const logCurrentFormState = (
    //     event: React.MouseEvent<HTMLButtonElement>
    // ) => {
    //     event.preventDefault(); // Prevents default button behavior
    //     console.log(getValues());
    // };

    useEffect(() => {
        if (getValues("giftTribute") === "") {
            setValue("giftTribute", "In Memory Of");
        }
    }, [getValues, setValue]);
    const tributeChecked = getValues("tributeChecked");

    //const notifyIndividualOrFamily = getValues("notifyIndividualOrFamily");

    // we use this to trigger a render operation when a checkbox is checked.  Used in the handleChange events for the checkboxes
    const [aCheckboxChanged, setaCheckboxChanged] = useState(false);
    const [showInMemoryOf, setShowInMemoryOf] = useState(false);
    const [showInHonorOf, setShowInHonorOf] = useState(false);

    useEffect(() => {
        const currentGiftTribute = getValues("giftTribute");
        setShowInMemoryOf(currentGiftTribute === "In Memory Of");
    }, []);

    const handleTributeChange = (event: any) => {
        setValue("tributeChecked", event.target.checked); // set the rect hook element appropriately
        setaCheckboxChanged(!aCheckboxChanged); // this will trigger a re-render of the page to hide/show elements
    };

    const handleClickNewAddress = (event: any) => {
        setValue("inMemoryNewAddress", event.target.checked); // set the rect hook array element appropriately
        setaCheckboxChanged(!aCheckboxChanged); // this will trigger a re-render of the page to hide/show elements
    };

    const handleMemHon = (event: any) => {
        const selectedValue = event.target.value;
        setValue("giftTribute", selectedValue);
        setShowInMemoryOf(selectedValue === "In Memory Of");

        // if we just switched to 'In Memory Of', select 'Please Notify the Individual/Family Below'
        if (event.target.value === "In Memory Of") {
            setValue("notifyIndividualOrFamily", C_NOTIFY_IND_FAMILY_BELOW);
        } else if (event.target.value === "In Honor Of") {
            setValue("notifyIndividualOrFamily", C_NOTIFY_PERSON_ABOVE);
        }

        setaCheckboxChanged(!aCheckboxChanged); // this will trigger a re-render of the page to hide/show elements
        setShowInMemoryOf(event.target.value === "In Memory Of");
    };

    // const handleClickNotify = (event: any) => {
    //     setValue("notifyIndividualOrFamily", event.target.checked); // set the rect hook array element appropriately
    //     setaCheckboxChanged(!aCheckboxChanged); // this will trigger a re-render of the page to hide/show elements
    // };

    const handleNotificationChange = (event: any) => {
        setValue("notifyIndividualOrFamily", event.target.value);
        setaCheckboxChanged(!aCheckboxChanged); // this will trigger a re-render of the page to hide/show elements
    };
    const divStyle = {
        flexBasis: "content",
        msFlexPreferredSize: "content",
    };

    const C_NOTIFY_PERSON_ABOVE = "Please Notify the Honoree";
    const C_DO_NOT_SEND = "Donor Requests No Notification Be Sent";
    const C_NOTIFY_IND_FAMILY_BELOW =
        "Please Notify the Individual/Family Below";

    useEffect(() => {
        setValue("notifyIndividualOrFamily", C_NOTIFY_IND_FAMILY_BELOW);
    }, []); // Per Bill, this should be changed. Lea Ann asked me to rearrange the order of the radio buttons.  I did that, but I'm not sure if this useEffect is still needed.

    return (
        <>
            <FormGroup className="pt-4">
                <Typography variant="h5" component="h3">
                    Tribute
                </Typography>
                <div className="mb-0 p-0">
                    <Col>
                        <FormControlLabel
                            label="Check if this Gift is a Tribute"
                            defaultValue={"false"}
                            checked={tributeChecked}
                            control={
                                <Checkbox
                                    placeholder="Check if this Gift is a Tribute"
                                    onChange={handleTributeChange}
                                />
                            }
                        />
                    </Col>
                </div>
                {tributeChecked && (
                    <>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="campus-Location"
                                name="gift-tribute-radio-buttons-group"
                                value={getValues("giftTribute")}
                                onChange={handleMemHon}
                            >
                                <Row className="mb-0">
                                    <Col xs={6} md={2} style={divStyle}>
                                        <FormControlLabel
                                            value="In Memory Of"
                                            control={<Radio />}
                                            onChange={handleRadioChange}
                                            label="In Memory Of"
                                        />
                                    </Col>
                                    <Col xs={6} md={2} style={divStyle}>
                                        <FormControlLabel
                                            value="In Honor Of"
                                            control={<Radio />}
                                            onChange={handleRadioChange}
                                            label="In Honor Of"
                                        />
                                    </Col>
                                </Row>
                            </RadioGroup>
                            <Row>
                                <Col xs={12} md={3}>
                                    <TextField
                                        {...register("tedTributeConstituentId")}
                                        placeholder="TED Constituent ID"
                                        className="maxWidth"
                                        label="TED Constituent ID"
                                    />
                                </Col>

                                <Col xs={12} md={4}>
                                    {tributeType === "In Memory Of" && (
                                        <TextField
                                            {...register("deceasedFullName")}
                                            placeholder="Deceased Full Name"
                                            label="Deceased Full Name"
                                            className="maxWidth"
                                            required
                                        />
                                    )}
                                    {tributeType === "In Honor Of" && (
                                        <TextField
                                            {...register("honoreeFullName")}
                                            placeholder="Honoree Full Name"
                                            label="Honoree Full Name"
                                            className="maxWidth"
                                            required
                                        />
                                    )}
                                </Col>
                            </Row>
                        </FormControl>
                        {!showInMemoryOf && (
                            <Row className="mb-0 p-0">
                                <Col xs={12} md={4}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={getValues(
                                                        "inMemoryNewAddress"
                                                    )}
                                                    onClick={
                                                        handleClickNewAddress
                                                    }
                                                />
                                            }
                                            {...register("inMemoryNewAddress")}
                                            label="New Address"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}

                        {/* We only want the address to be required if 'In honor of' is selected */}
                        <Address
                            prefix="tribute"
                            isRequired={
                                !showInMemoryOf &&
                                getValues("notifyIndividualOrFamily") ===
                                    C_NOTIFY_PERSON_ABOVE
                            }
                        />
                        {/* Only show this code if the tribute section of 'In Memory Of' is not selected
                         */}
                        {!showInMemoryOf && (
                            <Row className="p-0">
                                <Col xs={12} md={3}>
                                    <Controller
                                        name="designationPhoneNumber"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <PhoneInput
                                                {...register(
                                                    "designationPhoneNumber"
                                                )}
                                                placeholder="Phone"
                                                value={value}
                                                onChange={onChange}
                                                defaultCountry="US"
                                                id="designationPhoneNumber"
                                            />
                                        )}
                                    />
                                </Col>
                                <Col xs={12} md={3}>
                                    <TextField
                                        type="email"
                                        placeholder="Designation Email"
                                        label="Email"
                                        {...register("designationEmail")}
                                        className="maxWidth"
                                    />
                                </Col>
                            </Row>
                        )}
                        {/* <Button onClick={logCurrentFormState}>
                            Log Form State
                        </Button> */}
                        <Row className="mb-0 p-0">
                            <Col>
                                {/* <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={getValues(
                                                    "notifyIndividualOrFamily"
                                                )}
                                                onClick={handleClickNotify}
                                            />
                                        }
                                        label="Please notify the following individual/family of my gift"
                                        {...register(
                                            "notifyIndividualOrFamily"
                                        )}
                                    />
                                </FormGroup> */}

                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="notifyIndividualOrFamily"
                                        value={getValues(
                                            "notifyIndividualOrFamily"
                                        )}
                                        onChange={handleNotificationChange}
                                        name="notifyIndividualOrFamily"
                                    >
                                        {!showInMemoryOf && (
                                            <FormControlLabel
                                                value={C_NOTIFY_PERSON_ABOVE}
                                                control={<Radio />}
                                                label={C_NOTIFY_PERSON_ABOVE}
                                            />
                                        )}
                                        <FormControlLabel
                                            value={C_NOTIFY_IND_FAMILY_BELOW}
                                            control={<Radio />}
                                            label={C_NOTIFY_IND_FAMILY_BELOW}
                                        />
                                        <FormControlLabel
                                            value={C_DO_NOT_SEND}
                                            control={<Radio />}
                                            label={C_DO_NOT_SEND}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                        {getValues("notifyIndividualOrFamily") ===
                            C_NOTIFY_IND_FAMILY_BELOW && (
                            <>
                                <Row className="p-0">
                                    <Col xs={12} md={4}>
                                        <TextField
                                            {...register(
                                                "tedTributeAcknowledgedFirstName"
                                            )}
                                            placeholder="Acknowledgee First Name"
                                            className="maxWidth"
                                            label="First Name"
                                            required
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <TextField
                                            {...register(
                                                "tedTributeAcknowledgedLastName"
                                            )}
                                            placeholder="Acknowledgee Last Name"
                                            className="maxWidth"
                                            label="Last Name"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col>
                                        <Address
                                            prefix="acknowledgee"
                                            isRequired={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={12} md={4}>
                                        <Controller
                                            name="tedTributeAcknowledgedPhone"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <PhoneInput
                                                    {...register(
                                                        "tedTributeAcknowledgedPhone"
                                                    )}
                                                    placeholder="Phone"
                                                    value={value}
                                                    onChange={onChange}
                                                    defaultCountry="US"
                                                    id="tedTributeAcknowledgedPhone"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <TextField
                                            {...register(
                                                "notifyIndividualOrFamilyEmail"
                                            )}
                                            placeholder="Email"
                                            type="email"
                                            label="Email"
                                            className="maxWidth"
                                        ></TextField>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                )}
            </FormGroup>
        </>
    );
}
