import { Typography, Grid } from "@mui/material";
import SplitLocation from "./SplitLocation";
import CampusAddress from "./CampusAddress";
import GiftInKindEmailMessage from "./GiftInKindEmailMessage";
import { Col, Row } from "react-bootstrap";

type PrintCampusAddressSwapParams = {
    campusLocation: string;
    formType: string;
};

export default function PrintCampusAddressSwap({
    campusLocation,
    formType,
}: PrintCampusAddressSwapParams) {
    return (
        <Row className="headerPrint">
            <style>
                {`@media print {.headerPrint{display: block; .addr{display:flex; justify-content:flex-end !important}}}`}
            </style>

            <Row>
                <div className="d-flex col hdrCol ">
                    <img
                        src={process.env.PUBLIC_URL + "/logo.svg"}
                        alt="U of I Foundation Logo"
                        className="logo"
                    />
                </div>
                <Col className="verticalCenter">
                    <Typography
                        variant="h6"
                        className="printHeader"
                        component="h1"
                    >
                        <SplitLocation />
                    </Typography>
                </Col>
                <Col>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        {formType !== "Gift in Kind" && (
                            <CampusAddress
                                campusLocation={campusLocation}
                                formType={formType}
                            />
                        )}
                    </Grid>
                </Col>
            </Row>
            <Row>
                {formType === "Gift in Kind" && (
                    <GiftInKindEmailMessage formType={formType} />
                )}
            </Row>
        </Row>
    );
}
