import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SplitLocation from "./SplitLocation";

type addrType = {
    campusLocation: string;
};

export default function PrintCampusAddressSwapNoHeaderAddress({
    campusLocation,
}: addrType) {
    return (
        <Row className="headerPrint">
            <style>
                {`@media print {.headerPrint{display: block; padding-top:2em !important; .d-flex{display:flex !important}; .addr{display:flex; justify-content:flex-end !important}}}`}
            </style>
            {/* import {NumberFormat} from 'react-number-format'; */}
            <Row>
                <div className="d-flex col hdrCol">
                    <img
                        src={process.env.PUBLIC_URL + "/logo.svg"}
                        alt="U of I Foundation Logo"
                        className="logo"
                    />
                </div>
                <Col className="verticalCenter">
                    <Typography
                        variant="h6"
                        className="printHeader"
                        component="h1"
                    >
                        <SplitLocation />
                    </Typography>
                </Col>
            </Row>
        </Row>
    );
}
