import { Container, Link, Typography } from "@mui/material";
import { Row } from "react-bootstrap";
import InstructionsList from "./InstructionsList";
import CampusAddress from "./CampusAddress";
import { useFormContext } from "react-hook-form";
import DeedOrGift from "./DeedOrGift";

export type InstructionsGIKProps = {
    formType: string;
};

export default function InstructionsGIK({ formType }: InstructionsGIKProps) {
    const { getValues } = useFormContext();
    return (
        <div>
            <Container>
                <Row>
                    <Typography variant="h5" component="h2">
                        Instructions
                    </Typography>
                </Row>

                <Typography variant="body1" gutterBottom>
                    Gift in Kind transmittals are to be submitted promptly once
                    goods and/or services are received by your department but
                    not before the Gift in Kind is received.
                </Typography>

                <Typography>
                    Instructions on how to complete this form are{" "}
                    <Link
                        href="https://app.sharebase.com/#/document/14467/share/375-LTTbeCrSrwC8PDZjyD7--ynG1A6E "
                        underline="always"
                        rel="noreferrer"
                        target="_blank"
                    >
                        here
                    </Link>
                    . If you have any questions when filling out your gift
                    transmittal contact Gift Administration at 217-333-0675.
                </Typography>
                <InstructionsList isHomepage={false} />
                <CampusAddress
                    campusLocation={getValues("campusLocation")}
                    formType={formType}
                />
                <DeedOrGift formType={formType} />
                <br />
            </Container>
        </div>
    );
}
