import React, { useState } from "react";
import RadioButtons from "./RadioButtons";
import UrbanaAddress from "./UrbanaAddress";
import CampusAddress from "./CampusAddress";
import { useFormContext } from "react-hook-form";

type DepartmentCampusProps = {
    formType: string;
};

export default function DepartmentCampus({ formType }: DepartmentCampusProps) {
    const [radioChanged, setRadioChanged] = useState(false); // needed to force re-render
    const { getValues } = useFormContext();

    return (
        <>
            {formType !== "GiftInKind" ? (
                <>
                    <CampusAddress
                        campusLocation={getValues("campusLocation")}
                        formType={formType}
                    />
                    {formType !== "Check" ? (
                        <RadioButtons
                            variableName="campusLocation"
                            // required={true}
                            values={[
                                "Urbana-Champaign",
                                "Chicago",
                                "Springfield",
                            ]}
                            radioChangedFlag={radioChanged}
                            setRadioChangedFlag={setRadioChanged}
                        />
                    ) : null}
                </>
            ) : (
                <>
                    <UrbanaAddress />
                </>
            )}
        </>
    );
}
