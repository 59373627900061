import { ReactNode } from "react";
import CheckAddress from "./CheckAddress";
import ChicagoAddress from "./ChicagoAddress";
import GiftInKindEmailMessage from "./GiftInKindEmailMessage";
import SpringfieldAddress from "./SpringfieldAddress";
import UrbanaAddress from "./UrbanaAddress";

export type addrType = {
    campusLocation: string;
    formType: string;
};

export default function CampusAddress({ campusLocation, formType }: addrType) {
    var headerLine: ReactNode = "Mail this Transmittal and Gift to:";

    if (formType === "Cash") {
        headerLine = (
            <div>
                <span>Hand-deliver this Transmittal and Cash to:</span>
                <br />
            </div>
        );
    }
    // Removed the GiftInKind case here

    return (
        <>
            {formType === "Check" ? (
                <>
                    <CheckAddress headerLine={headerLine} />
                </>
            ) : (
                <>
                    {formType === "GiftInKind" ? (
                        <>
                            <GiftInKindEmailMessage formType={formType} />
                        </>
                    ) : (
                        <>
                            {campusLocation === "Urbana-Champaign" ? (
                                <>
                                    <UrbanaAddress headerLine={headerLine} />
                                    <br />
                                </>
                            ) : campusLocation === "Chicago" ? (
                                <ChicagoAddress headerLine={headerLine} />
                            ) : campusLocation === "Springfield" ? (
                                <SpringfieldAddress headerLine={headerLine} />
                            ) : null}
                        </>
                    )}
                </>
            )}
        </>
    );
}
