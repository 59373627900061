import React from "react";

interface ButtonMailtoProps {
    mailto: string;
    label: string;
}

const ButtonMailto = ({ mailto, label }: ButtonMailtoProps) => {
    return (
        <a
            href={`mailto:${mailto}`}
            onClick={(e) => {
                e.preventDefault();
                window.location.href = `mailto:${mailto}`;
            }}
        >
            {label}
        </a>
    );
};

export default ButtonMailto;
