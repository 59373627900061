import React from "react"; // Ensure React is imported
import { List, ListItem, ListItemText } from "@mui/material";

// Define the interface for the component props
interface InstructionslistProps {
    isHomepage: boolean;
}

// Update the component to accept isHomepage prop
const Instructionslist: React.FC<InstructionslistProps> = ({ isHomepage }) => {
    const message = `${
        !isHomepage ? "Please do not print from this page. " : ""
    }Fill out the form and click the "Print Preview" button.`;
    return (
        <div className="indent-list">
            <List
                sx={{
                    listStylePosition: "outside",
                }}
                className="pt-0"
            >
                <ListItem
                    sx={{
                        listStyleType: "disc",
                        display: "list-item",
                    }}
                >
                    <ListItemText primary="Fill out a separate transmittal based on the type of gift" />
                </ListItem>
                <ListItem
                    sx={{
                        listStyleType: "disc",
                        display: "list-item",
                    }}
                >
                    <ListItemText primary="Include any and all documentation associated with the gift(s)" />
                </ListItem>
                <ListItem
                    sx={{
                        listStyleType: "disc",
                        display: "list-item",
                    }}
                >
                    <ListItemText primary="All transmittals are one donor per transmittal" />
                </ListItem>

                <ListItem
                    sx={{
                        listStyleType: "disc",
                        display: "list-item",
                    }}
                >
                    <ListItemText primary={message} />
                </ListItem>

                <ListItem
                    sx={{
                        listStyleType: "disc",
                        display: "list-item",
                    }}
                >
                    <ListItemText primary="Required fields are marked with an *" />
                </ListItem>
            </List>
        </div>
    );
};

export default Instructionslist;
