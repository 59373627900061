// import { Helmet } from "react-helmet";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CashCheckGIKDataEntry from "../components/CashCheckGIKDataEntry";
import InstructionsCheck from "../components/InstructionsCheck";

type CheckParams = {
    formValues: any;
    setFormValues: Function;
};
export default function Check({ formValues, setFormValues }: CheckParams) {
    const methods = useForm({
        defaultValues: formValues,
        mode: "onChange",
    });

    const navigate = useNavigate();

    function removeEmptyFields(data: any) {}

    const onSubmit = (data: any) => {
        removeEmptyFields(data);
        setFormValues(data);
        navigate("/checkprint");
    };
    document.title = "U of I Foundation | Check Transmittal Form";
    return (
        <>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>U of I Foundation | Check Transmittal Form</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> * /}
            </Helmet> */}
            <InstructionsCheck />
            <FormProvider {...methods}>
                <CashCheckGIKDataEntry
                    onSubmit={onSubmit}
                    showAnonymousDonorCheckBox={false}
                    formType="Check"
                />
            </FormProvider>
        </>
    );
}
