import {
    Button,
    Grid,
    Container,
    // createTheme,
    // ThemeProvider,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { createRef } from "react";
import PrintContactAndDonorInfo from "./PrintContactAndDonorInfo";
import PrintTribute from "./PrintTribute";
import PrintDesignations from "./PrintDesignations";
import PrintTotals from "./PrintTotals";
import PrintGIKs from "./PrintGIKs";
import PrintCampusAddressSwap from "../SplitCampusAddressSwap";
import CampusAddress from "../CampusAddress";
import GiftInKindEmailMessage from "../GiftInKindEmailMessage";
import UrbanaAddress from "../UrbanaAddress";

export type CashCheckPrintParams = {
    formValues: any;
    returnUrl: string;
    formType: string;
};

const CashCheckGIKPrint = ({
    formValues,
    returnUrl,
    formType,
}: CashCheckPrintParams) => {
    const navigate = useNavigate();
    // const componentRef = useRef();
    const componentRef = createRef<HTMLDivElement>();

    const handlePrint = useReactToPrint({
        content: () => {
            return componentRef.current;
        },
    });
    return (
        <>
            <div ref={componentRef}>
                <Container>
                    <div id="timestamp"></div>
                    <Row>
                        <Col>
                            <PrintCampusAddressSwap
                                campusLocation={formValues.campusLocation}
                                formType={formType}
                            />
                        </Col>
                    </Row>
                    {formType === "Gift in Kind" ? (
                        <Container>
                            <Row className="hideForPrint">
                                <Row>
                                    <div
                                        className="col-1"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            className="buttonClass backButton"
                                            onClick={() => {
                                                navigate(returnUrl);
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                    <div
                                        className="col-3"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={handlePrint}
                                        >
                                            Print Document
                                        </Button>
                                    </div>
                                    <Col
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                        }}
                                    >
                                        <GiftInKindEmailMessage
                                            formType={formType}
                                        />

                                        {/* <CampusAddress
                                                campusLocation={
                                                    formValues.campusLocation
                                                }
                                                formType={formType}
                                            /> */}
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    ) : (
                        <Container>
                            <Row className="hideForPrint">
                                <>
                                    <Col>
                                        <Button
                                            variant="contained"
                                            className="buttonClass backButton"
                                            onClick={() => {
                                                navigate(returnUrl);
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Col>
                                    <div className="d-flex col justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={handlePrint}
                                        >
                                            Print Document
                                        </Button>
                                    </div>
                                </>

                                <Col>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="end"
                                    >
                                        <Row className="hideForPrint mt-3 mb-0">
                                            <CampusAddress
                                                campusLocation={
                                                    formValues.campusLocation
                                                }
                                                formType={formType}
                                            />
                                        </Row>
                                    </Grid>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    <PrintContactAndDonorInfo formValues={formValues} />

                    <Row>
                        <Col>
                            <PrintTotals
                                formValues={formValues}
                                showGIKTotal={formType === "Gift in Kind"}
                            />
                        </Col>
                    </Row>

                    <PrintTribute formValues={formValues} />

                    <div className="theDesignationResults">
                        <Col className="p-0">
                            <div className="resultItems">
                                <PrintDesignations formValues={formValues} />
                            </div>
                        </Col>
                    </div>

                    {formType === "Gift in Kind" ? (
                        <div className="theDesignationResults">
                            <Col className="p-0">
                                <div className="resultItems">
                                    <PrintGIKs formValues={formValues} />
                                </div>
                            </Col>
                        </div>
                    ) : (
                        " "
                    )}

                    <Row>
                        <Col>
                            <PrintTotals
                                formValues={formValues}
                                showGIKTotal={formType === "Gift in Kind"}
                            />
                        </Col>
                    </Row>
                    <Container>
                        <Row className="hiddenForPrint">
                            <Col>
                                <Button
                                    variant="contained"
                                    className="buttonClass backButton"
                                    onClick={() => {
                                        navigate(returnUrl);
                                    }}
                                >
                                    Back
                                </Button>
                            </Col>
                            <Col className="d-flex col justify-content-end">
                                <Button
                                    variant="contained"
                                    onClick={handlePrint}
                                >
                                    Print Document
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
            {/* </ThemeProvider> */}
        </>
    );
};

export default CashCheckGIKPrint;
